import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Header } from './Header';
import { Home } from './Home';
import { RankerMediaView } from './Home/RankerMediaView';
import { Dashboard } from '../Dashboard';
import { ChallengeManager } from '../ChallengeManager';
import { PortfolioRankerManager } from '../PortfolioRankerManager';
import { Batch } from '../Batch/BatchContainer';
import { useUser } from '../../contexts';
import './Main.scss';
import { LoadingProgress } from '../../components/LoadingProgress';

export const Main = (): JSX.Element => {
  const { path } = useRouteMatch();
  const { isLoading } = useUser();

  return (
    <div className="Main">
      {isLoading ? (
        <LoadingProgress />
      ) : (
        <>
          <Header />
          <div className="Main-container">
            <Switch>
              <Route path={`${path}dashboard`}>
                <Dashboard />
              </Route>
              <Route path={`${path}challenge-manager`}>
                <ChallengeManager />
              </Route>
              <Route path={`${path}portfolio-ranker`}>
                <PortfolioRankerManager />
              </Route>
              <Route path={`${path}batch`}>
                <Batch />
              </Route>
              <Route path={`${path}rank/media`}>
                <RankerMediaView />
              </Route>
              <Route>
                <Home />
              </Route>
            </Switch>
          </div>
        </>
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useKey } from 'rooks';
import { Input, Checkbox } from '@material-ui/core';
import { IconKidStar, IconKidStarFilled, Loader } from '@wirestock.io/design-components';
import Chip from '@mui/material/Chip';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TextField from '@mui/material/TextField';
import AutoAwesomeMotionRoundedIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import { KeyboardEvent } from '../../../services/KeyboardEventsService';
import {
  KEYBOARD_CONTEXT_MEDIA,
  KEYBOARD_CONTEXT_MEDIA_SLIDER,
  KEYBOARD_CONTEXT_POPUP,
} from '../../../constants/keyboard.constants';
import { useDebouncedEffect } from '../../../utils/useDebounceEffect';
import {
  useUpdateMedia,
  useUpdateCuratorNote,
  useMakeMediaAIGenerated,
  useMediaFavoriteRank,
} from '../../../queries';
import { StyledButton } from '../../../components/StyledButton';
import { MediaExternalCopies } from '../../../components/MediaExternalCopies';
import { MediaAdditionalInfo } from '../../../components/MediaAdditionalInfo';
import { MediaCategory } from '../../../components/MediaCategory';
import { MediaCountry } from '../../../components/MediaCountry';
import { MediaDatePicker } from '../../../components/MediaDatePicker';
import { MediaTypeSwitch } from '../../../components/MediaTypeSwitch';
import { MediaCameraInfo } from '../../../components/MediaCameraInfo';
import { MediaReleases } from '../../../components/MediaReleases';
import { MediaKeywords } from '../../../components/MediaKeywords';
import { MediaAgencies } from '../../../components/MediaAgencies';
import { DialogContainer } from '../../../components/Dialog';
import { RejectReason } from '../../../components/RejectReason';
import { OnlyShopReason } from '../../../components/OnlyShopReason';
import { RegenerateMediaData } from '../../../components/RegenerateMediaData';
import { BatchMediaFormProps, MediaItem, KeywordType } from '../../../types';
import './batch.media.form.scss';

export const BatchMediaForm = (props: BatchMediaFormProps) => {
  const {
    batch,
    media,
    user,
    descriptionSuggestions,
    descriptionSuggestionLoading,
    onSubmitMedia,
    onActionMedia,
    onUpdateMedia,
    onRefetchMedia,
  } = props;
  const [showReasonDialog, setShowReasonDialog] = useState<boolean>(false);
  const [showOnlyShopDialog, setShowOnlyShopDialog] = useState<boolean>(false);
  const [showRegenerateDialog, setShowRegenerateDialog] = useState<boolean>(false);
  const [mediaForm, setMediaForm] = useState<any>({
    media_ids: [],
    isFavorite: false,
    batchMedia: {
      data: {
        note_from_curator: '',
      },
    },
    externalCopies: {
      data: [],
    },
    user_id: 1,
    is_easy_submission: true,
    category_id: 1,
    title: '',
    description: '',
    license: '',
    type: '',
    city: '',
    note: '',
    is_mature: false,
    is_selling_to_agencies_allowed: null,
    is_release_needed: false,
    keywords: [],
    descriptionSuggestions: [],
    releases: [],
    agencies: [],
    is_ai_generated: false,
    source: null,
    source_id: null,
  });
  const [changedAttributes, setChangedAttributes] = useState<any>({});
  const [initialModel, setInitialModel] = useState<any>({});
  const [initialKeywords, setInitialKeywords] = useState<KeywordType[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [updateStatus, setUpdateStatus] = useState(0);
  const [reasonType, setReasonType] = useState('');
  const { mutateAsync: makeMediaAIGenerated, isLoading: isMakingMediaAIGenerated } = useMakeMediaAIGenerated();
  const { mutate: mediaFavoriteRank } = useMediaFavoriteRank();
  const updateMediaAction = useUpdateMedia({
    retryDelay: 1000,
    onMutate: () => {
      setUpdateStatus(1);
    },
    onSuccess: (res: any) => {
      const response = res.data[0];
      const changedData: any = {};

      Object.keys(changedAttributes).forEach((key: any) => {
        changedData[key] = response[key] ?? '';
      });

      if (response?.validation?.data?.errors && !user?.isCurator) {
        setErrors({ ...response.validation.data.errors });
        onUpdateMedia(media, {
          withError: true,
          submitted: false,
          validation: response.validation,
          ...changedData,
        });
      }
      if (!Object.keys(response?.validation?.data?.errors).length || user?.isCurator || user?.isReviewer) {
        onUpdateMedia(media, {
          withError: false,
          submitted: true,
          validation: response.validation,
          ...changedData,
        });
      }
      setInitialModel({
        ...initialModel,
        ...changedData,
      });
      setUpdateStatus(2);
    },
    onError: (error: any) => {
      setErrors({ ...error.data.errors });
      onUpdateMedia(media, {
        withError: true,
        submitted: false,
        validation: {
          data: {
            errors: error.data.errors,
          },
        },
      });
      setUpdateStatus(3);
    },
  });
  const updateMediaNote = useUpdateCuratorNote({
    onSuccess: () => {
      onUpdateMedia(media, {
        batchMedia: mediaForm.batchMedia,
      });
    },
  });

  const openReasonDialog = (type: string) => {
    if (!media.length) return;

    setShowReasonDialog(true);
    setReasonType(type);
  };

  const openOnlyShopDialog = () => {
    if (!media.length) return;

    setShowOnlyShopDialog(true);
  };

  const isSingleMediaSelected = !!media.length && (media.length === 1);

  const submitMediaItem = (mediaList: MediaItem[]) => {
    mediaList.forEach((mediaItem: MediaItem) => {
      if (!Object.keys(errors).length) {
        onSubmitMedia(mediaItem.id);
      }
    });
  };

  const onActionSuccess = (actionType: any) => {
    onActionMedia(media, {
      type: actionType,
      data: {
        show: false,
        selected: false,
      },
    });
  };

  const onOnlyShopSuccess = () => {
    setShowOnlyShopDialog(false);
    onActionMedia(media, {
      type: 'reject',
      data: {
        show: false,
        is_selling_to_agencies_allowed: false,
        selected: false,
      },
    });
  };

  const onRegenerateMediaDataSuccess = (generatedResponse: any) => {
    const filteredKeywords = generatedResponse?.keywords;
    let filteredDescription = generatedResponse?.description;
    let filteredTitle = generatedResponse?.title;

    if (mediaForm.is_ai_generated) {
      filteredKeywords.push('ai', 'generative', 'illustration', 'digital', 'art');
      filteredDescription = `AI-generated ${filteredDescription.toLowerCase()}`;
      filteredTitle = `AI-generated ${filteredTitle.toLowerCase()}`;
    }

    const generatedKeywords = filteredKeywords.map((keyword: string, index: number) => {
      return {
        media_id: media[0].id,
        order: index,
        keyword: keyword,
      };
    });
    setMediaForm({
      ...mediaForm,
      title: filteredTitle,
      description: filteredDescription,
      keywords: filteredKeywords,
    });
    setInitialKeywords(generatedKeywords);
  };

  const onAgencyRejectSuccess = (actionType: any, agencyId: number) => {
    media.forEach((item: any) => {
      const mediaAgencies = item.agenciesMedia.data;
      mediaAgencies.forEach((agency: any) => {
        if (agency.agency_id === agencyId) {
          agency.status = 'rejected';
        }
        return agency;
      });

      onActionMedia([item], {
        type: actionType,
        data: {
          agenciesMedia: {
            data: mediaAgencies,
          },
        },
      });
    });
  };

  const matureCheckboxChange = (e: any) => {
    setMediaForm({ ...mediaForm, is_mature: e.target.checked });
  };

  const applyAIGeneratedActions = async () => {
    const mediaIds = media.map((item) => item.id);
    setUpdateStatus(1);
    for (const mediaId of mediaIds) {
      await makeMediaAIGenerated([mediaId]);
    }

    const changedMedia = await onRefetchMedia();

    const changedData = {
      title: changedMedia[0]?.title ? changedMedia[0]?.title : '',
      description: changedMedia[0]?.description ? changedMedia[0]?.description : '',
      keywords: changedMedia[0]?.keywords,
      type: changedMedia[0]?.type,
      is_ai_generated: !!changedMedia[0]?.details?.data?.is_ai_generated,
    };

    setUpdateStatus(0);
    setInitialModel({
      ...initialModel,
      ...changedData,
    });
    setInitialKeywords(changedMedia[0]?.keywords);
    setMediaForm({
      ...mediaForm,
      ...changedData,
    });
  };

  const applyMediaFavorite = async () => {
    setMediaForm({ ...mediaForm, isFavorite: true });

    await mediaFavoriteRank({
      mediaId: mediaForm.media_ids[0],
      score: 1,
    });
  };

  const onDescriptionChange = (e: any) => {
    let text = e.target.value;
    text = text.split(' ').splice(1, text.length).join(' ');
    text = text.charAt(0).toUpperCase() + text.slice(1);

    if (user?.isKeyworder || (user?.isReviewer && batch.purpose === 'auto-fill-content-reviewing')) {
      setMediaForm({ ...mediaForm, title: text, description: e.target.value });
    } else {
      setMediaForm({ ...mediaForm, description: e.target.value });
    }
  };

  const onDescriptionSelected = (suggestion: string) => {
    let text = suggestion;
    text = text.split(' ').splice(1, text.length).join(' ');
    text = text.charAt(0).toUpperCase() + text.slice(1);

    if (user?.isKeyworder) {
      setMediaForm({ ...mediaForm, title: text, description: suggestion });
    } else {
      setMediaForm({ ...mediaForm, description: suggestion });
    }
  };

  const changedKeys = (o1: any, o2: any) => {
    const keys = _.union(_.keys(o1), _.keys(o2));
    return _.filter(keys, (key) => {
      return o1[key] !== o2[key];
    });
  };

  useEffect(() => {
    if (media.length && (mediaForm.media_ids[0] !== media.map(i => i.id)[0])) {
      const data = {
        ...mediaForm,
        ...{
          media_ids: media?.map(i => i.id),
          isFavorite: false,
          batchMedia: media[0]?.batchMedia?.data?.note_from_curator ? media[0]?.batchMedia : { data: { note_from_curator: '' } },
          is_mature: !!media[0]?.is_mature,
          is_selling_to_agencies_allowed: media[0]?.is_selling_to_agencies_allowed,
          is_easy_submission: !!media[0]?.is_easy_submission,
          is_release_needed: !!media[0]?.is_release_needed,
          externalCopies: media[0]?.externalCopies,
          user_id: media[0]?.creator?.data?.id,
          title: media[0]?.title ? media[0]?.title : '',
          description: media[0]?.description ? media[0]?.description : '',
          keywords: media[0]?.keywords,
          type: media[0]?.type,
          category_id: media[0]?.category_id,
          photo_shoot_at: media[0]?.photo_shoot_at ? media[0]?.photo_shoot_at : '',
          country_id: media[0]?.country_id,
          city: media[0]?.city ? media[0]?.city : '',
          license: media[0]?.license,
          note: media[0]?.note || '',
          is_ai_generated: !!media[0]?.details?.data?.is_ai_generated,
          source: media[0]?.source?.source,
          source_id: media[0]?.source?.source_id,
        },
      };
      setUpdateStatus(0);
      setChangedAttributes({});
      setInitialModel(data);
      setInitialKeywords(media[0]?.keywords);
      setMediaForm(data);
    }
    if (media.length && media[0].validation && media[0].validation.data.errors) {
      setErrors(media[0].validation.data.errors);
    }
  }, [media]);

  useEffect(() => {
    const keywordsChange = _.isEqualWith(
      mediaForm.keywords,
      initialModel.keywords?.map((keyword: any) => keyword.keyword) || [],
    );
    const changedProperties: any = changedKeys(mediaForm, initialModel).filter((property: string) => {
      return ((property === 'keywords' && !keywordsChange) || property !== 'keywords') && property;
    });
    const filteredProperties: any = {};
    changedProperties.forEach((key: any) => {
      filteredProperties[key] = mediaForm[key];
    });

    Object.keys(filteredProperties).length < 16 && setChangedAttributes({ ...filteredProperties });
  }, [mediaForm]);

  useDebouncedEffect(() => {
    if (Object.keys(changedAttributes).length) {
      (user?.isCurator) && mediaForm.media_ids.length && updateMediaNote.mutate({
        batchId: batch.id,
        body: {
          ...changedAttributes,
          media_ids: mediaForm.media_ids,
        },
      });
      (user?.isCurator) && mediaForm.media_ids.length && Object.keys(changedAttributes).length && updateMediaAction.mutate({
        ...changedAttributes,
        media_ids: mediaForm.media_ids,
      });
      (user?.isKeyworder || user?.isReviewer) && mediaForm.media_ids.length && updateMediaAction.mutate({
        ...changedAttributes,
        media_ids: media.map(item => item.id),
      });
    }
  }, [changedAttributes], 500);

  useEffect(() => {
    if (showReasonDialog || showOnlyShopDialog) {
      KeyboardEvent.setContext(KEYBOARD_CONTEXT_POPUP);
    } else {
      KeyboardEvent.setContext(KEYBOARD_CONTEXT_MEDIA);
    }
  }, [showReasonDialog, showOnlyShopDialog]);

  useKey(82, () => KeyboardEvent.trigger([KEYBOARD_CONTEXT_MEDIA, KEYBOARD_CONTEXT_MEDIA_SLIDER], () => !user?.isKeyworder && openOnlyShopDialog()));
  useKey(86, () => KeyboardEvent.trigger([KEYBOARD_CONTEXT_MEDIA, KEYBOARD_CONTEXT_MEDIA_SLIDER], () => !user?.isKeyworder && openReasonDialog('revise')));

  return (
    <div className="BatchMediaForm">
      {!media.length && (
        <div className="batch-media-no-selected">
          <div className="no-media-container">
            <AutoAwesomeMotionRoundedIcon />
            <p>Please select at least one media to see the available properties.</p>
          </div>
        </div>
      )}
      {!!media.length && (
        <div className="batch-media-form-container">
          <div className="media-update-status">
            <span
              className={
                clsx('status-container', {
                  'no-change': updateStatus === 0,
                  saving: updateStatus === 1,
                  saved: updateStatus === 2,
                  failed: updateStatus === 3,
                })
              }
            >
              <span className="no-change">No change</span>
              <span className="saving">
                <AutorenewIcon />
                Saving...
              </span>
              <span className="saved">
                <TaskAltIcon />
                Saved!
              </span>
              <span className="failed">
                <ErrorOutlineIcon />
                Failed
              </span>
            </span>
          </div>
          {!!media.length && (
            <div className="batch-media-license-type">
              <p className="capitalized-paragraph">{media[0].type} /</p>
              <p>{media[0].license} /</p>
              <div className="form-group-container">
                <Checkbox
                  checked={mediaForm.is_mature}
                  onChange={matureCheckboxChange}
                />
                Mature
              </div>
            </div>
          )}
          <div className="batch-media-form-actions-container">
            <div className="batch-media-action-buttons">
              {((user?.isCurator) || (user?.isReviewer)) && (
                <>
                  <StyledButton
                    className="batch-media-flag"
                    variant="outlined"
                    color="error"
                    disableElevation
                    onClick={() => openReasonDialog('reject')}
                  >
                    Reject
                  </StyledButton>
                  <StyledButton
                    className="batch-media-ready button-orange"
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={() => openReasonDialog('revise')}
                  >
                    Revise
                  </StyledButton>
                  <StyledButton
                    className="batch-media-ready"
                    variant="outlined"
                    disableElevation
                    onClick={() => openOnlyShopDialog()}
                    disabled={mediaForm.is_selling_to_agencies_allowed === 0}
                  >
                    Only Shop
                  </StyledButton>
                  {(user?.isCurator || user?.isReviewer) && (media[0].type === 'photo' || media[0].type === 'illustration') && (
                    <StyledButton
                      className="batch-media-ai"
                      variant="outlined"
                      disableElevation
                      onClick={applyAIGeneratedActions}
                    >
                      {isMakingMediaAIGenerated ? <Loader color="black" isLoading size={26} /> : 'AI Content'}
                    </StyledButton>
                  )}
                  {(user?.isCurator || user?.isReviewer) && (media[0].type === 'photo' || media[0].type === 'illustration') && (
                    <StyledButton
                      color="success"
                      variant="outlined"
                      title="Favorite"
                      onClick={applyMediaFavorite}
                    >
                      {mediaForm.isFavorite ? (
                        <IconKidStarFilled height={28} />
                      ) : (
                        <IconKidStar height={28} />
                      )}
                    </StyledButton>
                  )}
                </>
              )}
              {(user?.isKeyworder) && (
                <>
                  <StyledButton
                    className="batch-media-ready"
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={() => openReasonDialog('revise')}
                  >
                    Revise
                  </StyledButton>
                </>
              )}
              <DialogContainer
                open={showReasonDialog}
                dialogCloseHandler={setShowReasonDialog}
              >
                <RejectReason
                  mediaList={media}
                  onActionSuccess={onActionSuccess}
                  dialogCloseHandler={setShowReasonDialog}
                  reasonType={reasonType}
                />
              </DialogContainer>
              <DialogContainer
                open={showOnlyShopDialog}
                dialogCloseHandler={setShowOnlyShopDialog}
              >
                <OnlyShopReason
                  mediaList={media}
                  onActionSuccess={onOnlyShopSuccess}
                  dialogCloseHandler={setShowOnlyShopDialog}
                />
              </DialogContainer>
              <DialogContainer
                open={showRegenerateDialog}
                dialogCloseHandler={setShowRegenerateDialog}
              >
                <RegenerateMediaData
                  mediaItem={{
                    ...media[0],
                    ...mediaForm,
                  }}
                  onActionSuccess={onRegenerateMediaDataSuccess}
                  dialogCloseHandler={setShowRegenerateDialog}
                />
              </DialogContainer>
            </div>
          </div>
          {((user?.isCurator) || (user?.isReviewer) || (user?.isKeyworder)) && (
            <>
              {!!media.length && (media.length === 1) && !(user?.isReviewer) && (
                <div className="form-group media-camera">
                  <div className="form-group-label">Camera</div>
                  <div className="form-group-container">
                    <MediaCameraInfo info={media[0].details?.data} />
                  </div>
                </div>
              )}
              {!!media.length && (media.length === 1) && !!media[0].externalCopies && (
                !!media[0].externalCopies.data.length && (
                  <div className="form-group media-external-copies">
                    <div className="form-group-label">External Copies</div>
                    <div className="form-group-container">
                      <MediaExternalCopies copies={media[0].externalCopies.data} />
                    </div>
                  </div>
                )
              )}
              {isSingleMediaSelected && media[0].profile_caption && (
                <div className="form-group form-group-readonly">
                  <div className="form-group-label">Caption</div>
                  <div className="form-group-container">
                    <MediaAdditionalInfo
                      info={{
                        note: media[0].profile_caption,
                      }}
                    />
                  </div>
                </div>
              )}
              {isSingleMediaSelected && media[0].note && (
                <div className="form-group form-group-readonly">
                  <div className="form-group-label">Extra details for discovery</div>
                  <div className="form-group-container">
                    <MediaAdditionalInfo
                      info={{
                        note: media[0].note,
                      }}
                    />
                  </div>
                </div>
              )}
              {!(user.isReviewer) && media.length && (media.length === 1) && !!media[0].releases.data.length && (
                <div className="form-group media-releases">
                  <div className="form-group-label">Release Information</div>
                  <div className="form-group-container">
                    <MediaReleases releases={media[0].releases} />
                  </div>
                </div>
              )}
              {!(user.isReviewer) && (
                <div className="form-group media-note-from-curator">
                  <div className="form-group-label">Note from Curator</div>
                  <div className="form-group-container">
                    <Input
                      defaultValue={mediaForm.batchMedia.data.note_from_curator}
                      value={mediaForm.batchMedia.data.note_from_curator}
                      onChange={(e) => setMediaForm({
                        ...mediaForm,
                        batchMedia: { data: { note_from_curator: e.target.value } },
                      })}
                      className="curator-note-input"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {((user?.isKeyworder) || (user?.isReviewer)) && (
            <>
              {isSingleMediaSelected && mediaForm.batchMedia.data.note_from_curator && (
                <div className="form-group form-group-readonly">
                  <div className="form-group-label">Note from Curator</div>
                  <div className="form-group-container">
                    <MediaAdditionalInfo
                      info={{
                        note: mediaForm.batchMedia.data.note_from_curator,
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="form-group-divided">
                <div className="form-group media-category">
                  <div className="form-group-label">Category</div>
                  <div className="form-group-container">
                    <MediaCategory
                      categoryId={media[0].category_id}
                      onChange={(category_id) => setMediaForm({ ...mediaForm, category_id })}
                    />
                  </div>
                  {(errors.category_id || errors.media_category_id) && (
                    <div className="form-group-error">
                      {errors.category_id || errors.media_category_id}
                    </div>
                  )}
                </div>
                {(media[0].type !== 'video') && (media[0].type !== 'vector') && (
                  <div className="form-group media-image-type">
                    <div className="form-group-label">Image Type</div>
                    <div className="form-group-container">
                      <MediaTypeSwitch
                        type={media[0].type}
                        onTypeChange={(type) => setMediaForm({ ...mediaForm, type })}
                      />
                    </div>
                  </div>
                )}
              </div>
              {isSingleMediaSelected && media[0].license === 'editorial' && (
                <>
                  <div className="form-group-divided">
                    <div className="form-group media-country">
                      <div className="form-group-label">Country</div>
                      <div className="form-group-container">
                        <MediaCountry
                          countryId={media[0].country_id}
                          readOnly
                          onChange={(country_id) => setMediaForm({ ...mediaForm, country_id })}
                        />
                      </div>
                      {errors.country_id && (
                        <div className="form-group-error">
                          {errors.country_id}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="form-group-label media-city">City</div>
                      <div className="form-group-container">
                        <Input
                          value={mediaForm.city}
                          readOnly
                          onChange={(e) => setMediaForm({ ...mediaForm, city: e.target.value })}
                          className="curator-note-input"
                        />
                      </div>
                      {errors.city && (
                        <div className="form-group-error">
                          {errors.city}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group-divided">
                    <div className="form-group media-photo-shoot-date">
                      <div className="form-group-label">Photo Shoot Date</div>
                      <div className="form-group-container">
                        <MediaDatePicker
                          date={media[0].photo_shoot_at}
                          readOnly
                          onChange={(date) => setMediaForm({ ...mediaForm, photo_shoot_at: date })}
                        />
                      </div>
                      {errors.photo_shoot_at && (
                        <div className="form-group-error">
                          {errors.photo_shoot_at}
                        </div>
                      )}
                    </div>
                    <div className="form-group" />
                  </div>
                </>
              )}
              {/* TODO: This is temporarily condition for testing only */}
              {(user?.id === 1930 || user?.id === 20167 || user?.id === 8112) && (
                <div className="form-group media-description-suggestions">
                  <div className="form-group-label">Suggestions</div>
                  <div className="form-group-container description-suggestions-container">
                    {isSingleMediaSelected && !descriptionSuggestionLoading ? (
                      <>
                        {descriptionSuggestions?.length ? descriptionSuggestions?.map((suggestion, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            color="primary"
                            label={suggestion}
                            onClick={() => onDescriptionSelected(suggestion)}
                            deleteIcon={<CloseRoundedIcon />}
                          />
                        )) : '-'}
                      </>
                    ) : (
                      <>
                        Loading...
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="form-group">
                <div className="form-group-label">Title / Description / Keywords</div>
                <div className="form-group-container">
                  {batch.purpose === 'auto-fill-content-reviewing' && (
                  <div className="form-media-regenerate-button">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => setShowRegenerateDialog(true)}
                    >
                      Edit
                    </StyledButton>
                  </div>
                  )}
                  {batch?.purpose !== 'auto-fill-content-reviewing' && (
                    <div className="form-group media-description">
                      <div className="form-group-label">Description</div>
                      <div className="form-group-container">
                        <TextField
                          value={mediaForm.description}
                          inputProps={{
                            spellCheck: true,
                          }}
                          name="description"
                          multiline
                          onChange={(e) => onDescriptionChange(e)}
                          className="curator-note-input"
                          variant="standard"
                        />
                      </div>
                      <div className="form-group-footer">
                        <div className="form-group-error">
                          {errors.description}
                        </div>
                        <div className="form-input-length">
                          <span>{mediaForm.description.length} / 150</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group media-title">
                    <div className="form-group-label">Title</div>
                    <div className="form-group-container">
                      <TextField
                        value={mediaForm.title}
                        inputProps={{
                          spellCheck: true,
                        }}
                        name="title"
                        multiline
                        onChange={(e) => setMediaForm({ ...mediaForm, title: e.target.value })}
                        className="curator-note-input"
                        variant="standard"
                      />
                    </div>
                    <div className="form-group-footer">
                      <div className="form-group-error">
                        {errors.title}
                      </div>
                      <div className="form-input-length">
                        <span>{mediaForm.title.length} / 100</span>
                      </div>
                    </div>
                  </div>
                  {user?.isReviewer && batch.purpose === 'auto-fill-content-reviewing' && (
                    <div className="form-group media-description">
                      <div className="form-group-label">Description</div>
                      <div className="form-group-container">
                        <TextField
                          value={mediaForm.description}
                          inputProps={{
                            spellCheck: true,
                          }}
                          name="description"
                          multiline
                          onChange={(e) => onDescriptionChange(e)}
                          className="curator-note-input"
                          variant="standard"
                        />
                      </div>
                      <div className="form-group-footer">
                        <div className="form-group-error">
                          {errors.description}
                        </div>
                        <div className="form-input-length">
                          <span>{mediaForm.description.length} / 150</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group media-keywords">
                    <div className="form-group-label">Keywords</div>
                    <div className="form-group-container">
                      <MediaKeywords
                        mediaId={media[0].id}
                        mediaInfo={media[0]}
                        suggestedKeywords={media[0]?.suggestedKeywords?.data[0]?.keywords}
                        canGenerate={user.isKeyworder}
                        defaultKeywords={initialKeywords}
                        errors={Object.keys(errors)
                          .filter((errorKey) => (/(keywords.)+([0-9])+(.keyword)/g).test(errorKey))
                          .map((keywordErrorKey) => {
                            return {
                              index: parseInt(keywordErrorKey.split('.')[1]),
                              errors: errors[keywordErrorKey],
                            };
                          })}
                        mediaContext={mediaForm.title}
                        onKeywordChange={(keywords) => setMediaForm({ ...mediaForm, keywords })}
                      />
                    </div>
                    {errors.keywords && (
                      <div className="form-group-error">
                        {errors.keywords}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isSingleMediaSelected && media[0].releases && !!media[0].releases.data.length && (
                <div className="form-group media-releases">
                  <div className="form-group-label">Release Information</div>
                  <div className="form-group-container">
                    <MediaReleases releases={media[0].releases} />
                  </div>
                </div>
              )}
              {!user?.isReviewer && (
                <div className="batch-media-submit">
                  <StyledButton
                    className="batch-media-submit-button"
                    variant="contained"
                    disabled={!!Object.keys(errors).length}
                    color="primary"
                    disableElevation
                    onClick={() => submitMediaItem(media)}
                  >
                    {user.isKeyworder ? 'Submit' : 'Save'}
                  </StyledButton>
                </div>
              )}
            </>
          )}
          {(user?.isCurator || user?.isReviewer) && (
            <div className="form-group media-agencies">
              <div className="form-group-label">Agencies</div>
              <div className="form-group-container">
                <MediaAgencies
                  media={media}
                  onAgencyReject={(agencyId: number) => onAgencyRejectSuccess('reject', agencyId)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
